import React from 'react'

export const SubAgent = () => {
  return (
    <div className="row about-style ">
    <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
    <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
        <h2 className="h1 mb-0">Sub Agents</h2>
    </div>
    <table 
    className='table mb-0'>
<tbody>
<tr>
<th>Designation</th>
<th>Name</th>
<th>Contact No.</th>
</tr>
<tr>
<td >Deputy Manager</td>
<td>Mr. Tejas Amin</td>
<td>Off Dir:24838956, GSM:93220893</td>
</tr>
<tr>
<td >Marketing Executive</td>
<td>Mr. Mubarak Al Shamaki</td>
<td>Off. Dir.:25363590,GSM: 99323995</td>
</tr>
<tr>
<td >Asst.Manager</td>
<td>Mr. Ajnas C.M</td>
<td>Off. Dir.:24838956, GSM: 93203490</td>
</tr>
<tr>
<td >Deputy Manager</td>
<td>Mr.Abhinaj</td>
<td>Off. Dir.:24838861, GSM: 92866046</td>
</tr>
<tr>
<td >Deputy Manager</td>
<td>Mr.Rizwan</td>
<td>Off Dir:24838955, GSM:99238809</td>
</tr>
<tr>
<td >Asst.Manager</td>
<td>Mr.Jimmy Samuel</td>
<td>Off. Dir.:26842593, GSM: 92875128</td>
</tr>
</tbody>
</table>
      </div>
</div>
  )
}
