import React ,{useEffect}from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useSelector,useDispatch } from "react-redux";
import { addItem } from '../../redux/Action/CartAction';


export default function Layout (props) {
 
  const dispatch = useDispatch();
  const incrData=useSelector(state=>state.cart);

  useEffect(() => {
     //   console.log(incrData);
  },[incrData]);

  const chnlang=(val)=>{
   dispatch(addItem({val}));
  }
  return (
    <div className="main-wrapper">
    <Header changeLang={(val)=>chnlang(val)}/>
    {props.children}
    <Footer/>
   
    </div>
  )
}
