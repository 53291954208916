import React from 'react'
import { ProductThumb } from './ProductThumb'

export const Commercial = () => {
  return (
    <section>
            <div className="container">
                <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
                    <span>Our Products</span>
                    <h2 className="display-22 display-sm-18 display-md-16 display-lg-11 mb-0">Commercial</h2>
                </div>
                <div className="row g-lg-5 mt-n2-9">
                <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/allrisk.jpeg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"ALL RISK"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf"}
                     claimDownloadlink={"/assets/pdf/all_risk-claim.pdf"}
                     isClaim={true}
                     isProps={false}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/all_risk_about.pdf"}
                     />
                     </div>

                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/fidelity.jpeg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"FIDELITY GUARANTEE"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/fidlity_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/fidelity_claim.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/fidelity_about.pdf"}
                     />
                     </div>
                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/marine-cargo.jpg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"MARINE CARGO POLICY"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/marine_cargo_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/marine_cargo_cliam.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/marine_cargo.pdf"}
                     />
                     </div>
                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/marine-hull.jpg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"MARINE HULL"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/marine_hull_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/marine_hull_claim.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={false}
                     isAbtDownloadUrl={"/assets/pdf/"}
                     />
                     </div>
                    
                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/money.jpg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"MONEY"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/money_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/money_claim.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={false}
                     isAbtDownloadUrl={"/assets/pdf/"}
                     />
                     </div>
                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/motor-1.jpg"}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={"MOTOR"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/commercial_motor_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/commercial_motor_claim.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={false}
                     isAbtDownloadUrl={"/assets/pdf/"}
                     />
                     </div>

                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/multicover.jpg"}
                     icon={"/assets/img/icons/icon-05.png"}
                     title={"OFFICE MULTICOVER"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf"}
                     claimDownloadlink={"/assets/pdf"}
                     isClaim={false}
                     isProps={false}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/office_multicover_abtus.pdf"}
                     />
                     </div>

                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/plate-glass.jpg"}
                     icon={"/assets/img/icons/icon-05.png"}
                     title={"PLATE GLASS INSURANCE "}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/plate_glass_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/plate_glass_claim.pdf"}
                     isClaim={false}
                     isProps={false}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/plate_glass_abouts.pdf"}
                     />
                     </div>
                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/special-events.jpg"}
                     icon={"/assets/img/icons/icon-05.png"}
                     title={"SPECIAL EVENTS"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/special_events_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf"}
                     isClaim={false}
                     isProps={true}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/special_events_abtus.pdf"}
                     />
                     </div>

                     <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                    <ProductThumb
                     thumb={"/assets/img/service/special-events.jpg"}
                     icon={"/assets/img/icons/icon-05.png"}
                     title={"WORK MEN COMPENSATION WITH EXTENSION OF EMPLOYER’S LIABILITY"}
                     shortDesc={""}
                     routeName={"product/Commercial"}
                     proposalDownloadlink={"/assets/pdf/workmens_compensation_proposal.pdf"}
                     claimDownloadlink={"/assets/pdf/workmen_claim.pdf"}
                     isClaim={true}
                     isProps={true}
                     isAbt={true}
                     isAbtDownloadUrl={"/assets/pdf/workmen_compensation_abt.pdf"}
                     />
                     </div>

                    


                    
                </div>
            </div>
           {/*<img src="/assets/img/content/shape2.png" className="position-absolute top-0 end-0 d-none d-md-block" alt="..."/>
            <img src="/assets/img/content/shape6.png" className="position-absolute bottom-20 left-5 ani-top-bottom d-none d-sm-block" alt="..."/>*/}
        </section>
  )
}
