import React from 'react'

export const FinancialRating = () => {
  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>Gallery</span>
            <h2 className="h1 mb-0">Financial Rating</h2>
            
        </div>
        <p className="mb-1-9   text-secondary lh34 ">
        For the sixth consecutive year, the Company has been rated as “A-” (Excellent) by M/s. A.M. Best Europe Ltd. The rating reflects Company’s excellent risk adjusted capitalisation, prospective improvement in underwriting performance and its leading business profile in the direct insurance market in India. A partially off-setting factor is the Company’s reliance on investment income which counter balances underwriting losses. But the outlook is stable. A.M. Best believes the Company’s risk adjusted capitalisation is excellent and anticipates that it will remain sufficient to absorb the likely growth in the net premium. Further it also expects that there will be a reduction in the combined ratio in the years to come. The Company is likely to maintain its leading business position as the largest direct insurer in India, despite increased competition from private players.
       </p>

       <div className="row about-style mb-2-9 mb-lg-6">
                <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-0'>
<tbody>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td colspan="2"><strong>(Rs. in Crores)</strong></td>
<td></td>
</tr>
<tr>
<td width="10%">Year</td>
<td width="14%">Gross Premium<br/>
(in India)</td>
<td width="16%">Gross Premium<br/>
(Outside India)</td>
<td width="15%">Net Premium (Global)</td>
<td width="14%">Net Profit (Global)</td>
<td width="16%">Total Assets (Global)</td>
<td width="15%">Net Worth (Global)</td>
</tr>
<tr>
<td>2021-2022</td>
<td>32573</td>
<td>2942</td>
<td>29760</td>
<td>164</td>
<td>94530</td>
<td>18232</td>
</tr>
<tr>
<td>2020-2021</td>
<td>28548</td>
<td>3025</td>
<td>26966</td>
<td>1605</td>
<td>90189</td>
<td>17786</td>
</tr>
<tr>
<td>2019-2020</td>
<td>26813</td>
<td>2901.9</td>
<td>24487.1</td>
<td>1417.8</td>
<td>74609.3</td>
<td>15726</td>
</tr>
<tr>
<td>2018-2019</td>
<td>23910</td>
<td>2697.8</td>
<td>22120.9</td>
<td>579.79</td>
<td>79508</td>
<td>15762</td>
</tr>
<tr>
<td>2017-2018</td>
<td>22719</td>
<td>3202.9</td>
<td>20956.4</td>
<td>2200.9</td>
<td>76626.8</td>
<td>15412</td>
</tr>
<tr>
<td>2016-2017</td>
<td>19115</td>
<td>3164</td>
<td>18590.9</td>
<td>1007.9</td>
<td>69172.8</td>
<td>11023</td>
</tr>
<tr>
<td>2015-2016</td>
<td>15150</td>
<td>3221.9</td>
<td>15911.9</td>
<td>828.69</td>
<td>62880.1</td>
<td>9821.9</td>
</tr>
<tr>
<td>2014-2015</td>
<td>13209</td>
<td>2840.6</td>
<td>13938.8</td>
<td>1431.2</td>
<td>61719.6</td>
<td>9722</td>
</tr>
<tr>
<td>2013-2014</td>
<td>11540</td>
<td>2763.8</td>
<td>12078.6</td>
<td>1089</td>
<td>53010.9</td>
<td>8621.3</td>
</tr>
<tr>
<td>2012-2013</td>
<td>10038</td>
<td>2466.6</td>
<td>10274.7</td>
<td>843.66</td>
<td>45375.5</td>
<td>7737.4</td>
</tr>
<tr>
<td>2011-2012</td>
<td>8542.9</td>
<td>1531</td>
<td>8771.21</td>
<td>179.31</td>
<td>42162.7</td>
<td>7057.6</td>
</tr>
<tr>
<td>2010-2011</td>
<td>7097.1</td>
<td>1128.4</td>
<td>7192.23</td>
<td>-421.6</td>
<td>39621.3</td>
<td>6890.5</td>
</tr>
<tr>
<td>2009-2010</td>
<td>6042.5</td>
<td>1056.6</td>
<td>6002.66</td>
<td>404.69</td>
<td>36832.9</td>
<td>7430.2</td>
</tr>
<tr>
<td>2008-2009</td>
<td>5508.8</td>
<td>946.96</td>
<td>5500.31</td>
<td>224.16</td>
<td>26931.6</td>
<td>7328</td>
</tr>
<tr>
<td>2007-2008</td>
<td>5276.91</td>
<td>874.55</td>
<td>4914.28</td>
<td>1401.13</td>
<td>31944.14</td>
<td>6972.80</td>
</tr>
<tr>
<td>2006-2007</td>
<td>5017.20</td>
<td>919.58</td>
<td>4751.76</td>
<td>1459.95</td>
<td>27444.57</td>
<td>5972.55</td>
</tr>
<tr>
<td>2005-2006</td>
<td>4791.49</td>
<td>884.05</td>
<td>4342.66</td>
<td>716.38</td>
<td>27025.58</td>
<td>4706.87</td>
</tr>
<tr>
<td>2004-2005</td>
<td>4210.81</td>
<td>892.35</td>
<td>3895.11</td>
<td>402.23</td>
<td>19827.19</td>
<td>4161.69</td>
</tr>
<tr>
<td>2003-2004</td>
<td>4045.68</td>
<td>875.79</td>
<td>3634.94</td>
<td>590.21</td>
<td>17510.44</td>
<td>3735.22</td>
</tr>
<tr>
<td>2002-2003</td>
<td>3921.24</td>
<td>891.55</td>
<td>3516.43</td>
<td>255.81</td>
<td>12984.75</td>
<td>3404.00</td>
</tr>
<tr>
<td>2001-2002</td>
<td>3512.33</td>
<td>685.73</td>
<td>3068.23</td>
<td>142.00</td>
<td>12273.02</td>
<td>3189.39</td>
</tr>
<tr>
<td>2000-2001</td>
<td>3041.17</td>
<td>451.88</td>
<td>2671.48</td>
<td>173.54</td>
<td>8292.00</td>
<td>3067.39</td>
</tr>
<tr>
<td>1999-2000</td>
<td>2979.53</td>
<td>327.00</td>
<td>2477.45</td>
<td>287.29</td>
<td>7664.71</td>
<td>2859.86</td>
</tr>
<tr>
<td>1998-1999</td>
<td>2729.48</td>
<td>288.16</td>
<td>2186.92</td>
<td>375.00</td>
<td>6727.72</td>
<td>2524.23</td>
</tr>
<tr>
<td>1997-1998</td>
<td>2433.73</td>
<td>254.04</td>
<td>1945.00</td>
<td>470.94</td>
<td>6071.67</td>
<td>1462.52</td>
</tr>
</tbody>
</table>
                  </div>
  </div>
        </div>
        </section>
  )
}
