import React from 'react'
import { ProductThumb } from './ProductThumb'

export const Liability = () => {
  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>Our Products</span>
            <h2 className="display-22 display-sm-18 display-md-16 display-lg-11 mb-0">Liability</h2>
        </div>
        <div className="row g-lg-5 mt-n2-9">
        <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/CARRIERS-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"CARRIERS LIABILITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_carriers_legal_liability_proposal.pdf"}
             claimDownloadlink={"/assets/pdf/liability_carrier_legal_liability_claim.pdf"}
             isClaim={true}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_carriers_liability_abt.pdf"}
             />
             </div>

             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/DIRECTORS-AND-OFFICERS-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"DIRECTOR'S AND OFFICERS LIABILITY"}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_directors_officers_props.pdf"}
             claimDownloadlink={"/assets/pdf/"}
             isClaim={false}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_directors_office_liability_abt.pdf"}
             />
             </div>
             
             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/EMPLOYERS-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"EMPLOYER'S LIABILITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/"}
             claimDownloadlink={"/assets/pdf/"}
             isClaim={false}
             isProps={false}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_employers_liability_abt.pdf"}
             />
             </div>
            
             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/LIFT-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"LIFT LIABILITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_life_liability_proposal.pdf"}
             claimDownloadlink={"/assets/pdf/liability_lift_liability_claim.pdf"}
             isClaim={true}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_lift_liability_abt.pdf"}
             />
             </div>

             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/PRODUCTS-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"PRODUCTS LIABILITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_product_liability_proposal.pdf"}
             claimDownloadlink={"/assets/pdf/liability_product_liability_claim.pdf"}
             isClaim={true}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_product_liability_abt.pdf"}
             />
             </div>

             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/PROFESSIONAL-INDEMNITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"PROFESSIONAL INDEMNITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_professional_indemnity_proposal.pdf"}
             claimDownloadlink={"/assets/pdf/liability_professional_indemnity_doctor_proposal.pdf"}
             isClaim={true}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_profession_indemnity_abt.pdf"}
             />
             </div>

             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <ProductThumb
             thumb={"/assets/img/service/PUBLIC-LIABILITY.jpg"}
             icon={"/assets/img/icons/icon-03.png"}
             title={"PUBLIC LIABILITY             "}
             shortDesc={""}
             routeName={"product"}
             proposalDownloadlink={"/assets/pdf/liability_public_liability_proposal.pdf"}
             claimDownloadlink={"/assets/pdf/liability_public_liability_claim.pdf"}
             isClaim={true}
             isProps={true}
             isAbt={true}
             isAbtDownloadUrl={"/assets/pdf/liability_public_liability_abt.pdf"}
             />
             </div>
            
        </div>
    </div>
   {/*<img src="/assets/img/content/shape2.png" className="position-absolute top-0 end-0 d-none d-md-block" alt="..."/>
    <img src="/assets/img/content/shape6.png" className="position-absolute bottom-20 left-5 ani-top-bottom d-none d-sm-block" alt="..."/>*/}
</section>
  )
}
